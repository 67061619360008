/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef } from 'react'
import { GlobalContext } from '../..'
import { createPost, updatePost } from '../../services/post.service'
import Button from '../../components/Button'
import profilePicture from '../../assets/svgs/avtar-rect.svg'
import {
  Box,
  Center,
  HStack,
  IconButton,
  Button as ButtonChakra,
  useBoolean,
  Text,
  Icon,
  VStack,
  useToken,
  Divider,
  ModalBody,
  Modal,
  ModalOverlay,
  ModalCloseButton,
  ModalContent,
  useToast,
} from '@chakra-ui/react'
import { IoPersonOutline, IoRemove, IoAdd, IoAttachOutline } from 'react-icons/io5'
import { IoMdAttach, IoIosStarOutline } from 'react-icons/io'
import useState from 'react-usestateref'
import { getMyContacts } from '../../services/user.service'
import { CustomSelect } from '../../components/CustomSelect'
import PrivacySelector, { PRIVACY_MODES } from '../../components/PrivacySelector'
import { getFileTypeAndExtension } from '../../utils/file-utils'
import PostCarousel from '../../components/PostCarousel'
import RePost from './RePost'
import EmojiPickerPopover from '../Message/components/EmojiPickerPopover'

const CreatePost = ({
  onPost,
  onClose,
  postData = {
    id: null,
    text: '',
    attachments: [],
    tags: [],
    privacy_mode: 'public',
    shareTo: [],
    repostId: null,
  },
  repostId,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [userContacts, setUserContacts] = useState([])
  const { userInfo } = useContext(GlobalContext)
  const [showPhotoPicker, setShowPhotoPicker] = useBoolean()
  const [showTagPicker, setShowTagPicker] = useBoolean()
  const fileInputRef = useRef(null)
  const [attachments, setAttachments] = useState([])
  const [tags, setTags] = useState([])
  const [shareToUsers, setSharedToUsers] = useState([])
  const [privacyMode, setPrivacyMode] = useState(postData?.privacy_mode || 'public')
  const [attachmentsRemoved, setAttachmentsRemoved, attachmentsRemovedRef] = useState([])
  const [currentImageIndex, setCurrentImageIndex] = useState(0)
  const [defaultIndex, setDefaultIndex] = useState(0)

  const toast = useToast()

  const isAdd = !!!postData.id

  const [, graySoft, primary500] = useToken('colors', ['gray.700', 'gray.600', 'primary.700'])
  const [post, setPost] = useState(postData?.text || '')

  useEffect(() => {
    onGetMyContacts()
    return () => {}
  }, [])

  useEffect(() => {
    let tags = postData.tags || []
    let tagsData =
      tags?.map((item) => {
        let newItem = { ...item }
        newItem.value = item._id
        newItem.name = item.name || ''
        newItem.label = item.name || ''
        return newItem
      }) || []
    if (tags.length > 0) {
      setShowTagPicker.toggle()
    }
    setTags(tagsData)
  }, [postData.tags])

  useEffect(() => {
    let attachments = postData.attachments || []
    let photosData =
      attachments?.map((x) => {
        return {
          ...x,
          original: x.url,
          thumbnail: x.url,
        }
      }) || []

    if (photosData.length > 0) {
      setShowPhotoPicker.toggle()
    }

    let defaultIndex = attachments.findIndex((x) => x?.isDefault)
    defaultIndex = defaultIndex < 0 ? 0 : defaultIndex
    setDefaultIndex(defaultIndex)
    setAttachments(photosData)
  }, [postData.attachments])

  const onGetMyContacts = async () => {
    try {
      let { data: myContacts } = await getMyContacts()
      let mappedUsers = myContacts
        ? myContacts.map((item) => {
            let newItem = { ...item }
            newItem.value = item.id
            newItem.name = item.name || ''
            newItem.label = item.name || ''
            return newItem
          })
        : []
      let shareTo = postData?.shareTo || []
      let selected = mappedUsers.filter((x) => shareTo.includes(x.id))
      setSharedToUsers(selected)
      setUserContacts(mappedUsers || [])
      return myContacts
    } catch (error) {
      toast({
        title: 'Oops! Something Went Wrong',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  const handleOnImagePick = (event) => {
    if (!event.target.files[0]) return

    let newFiles = []
    Array.from(event?.target?.files)?.forEach((file) => {
      const { fileType } = getFileTypeAndExtension(file.name)
      const generatedUrl =
        fileType === 'photo' || fileType === 'gif' || true ? URL.createObjectURL(file) : ''
      let updated = {
        original: generatedUrl,
        thumbnail: generatedUrl,
        added: true,
        file,
        fileName: file.name,
        type: fileType,
      }
      newFiles.push(updated)
    })
    setAttachments([...attachments, ...newFiles])
  }

  const handleOnImageRemoved = () => {
    if (attachments.length < 1) {
      return
    }

    let removedPhoto = attachments.find((x, index) => index === currentImageIndex)

    if (removedPhoto?.added) {
    } else {
      setAttachmentsRemoved([...attachmentsRemoved, removedPhoto])
    }

    let updatedPhotos = attachments.filter((x, index) => index !== currentImageIndex)

    setAttachments(updatedPhotos || [])
    setCurrentImageIndex(0)
  }

  const onPostHandle = async () => {
    try {
      if (!post && attachments?.length < 1) return

      setIsLoading(true)

      let filesAdded = attachments?.filter((x) => x?.added) || []

      let shareTo = privacyMode === 'private' ? shareToUsers.map((x) => x.id) : []

      if (isAdd) {
        await createPost({
          userId: userInfo.id,
          type: 1,
          text: post.trim(),
          tags: tags || [],
          files: filesAdded,
          privacy_mode: privacyMode,
          defaultIndex,
          shareTo,
          repostId: repostId || '',
        })
      } else {
        await updatePost({
          id: postData.id,
          userId: userInfo.id,
          type: 1,
          text: post.trim(),
          files: filesAdded,
          tags: tags || [],
          privacy_mode: privacyMode,
          defaultIndex,
          shareTo,
          removed: attachmentsRemovedRef?.current.map((x) => x._id) || [],
        })
      }
      onPost()
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      toast({
        title: 'Oops! Something Went Wrong',
        description:
          error.message === 'Request failed with status code 409'
            ? 'The content of your post violates the safety policies of Co-Nectar as a platform'
            : error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  const profilePhoto = userInfo && userInfo.photo ? userInfo.photo : profilePicture
  const isCurrentDefault = defaultIndex === currentImageIndex
  return (
    <Modal isOpen={true} onClose={onClose} size={'2xl'} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Box className="flex flex-col flex-1 justify-center py-6">
            <div className={'flex flex-col'}>
              <div className="flex">
                <div className="m-2 w-10 py-1">
                  <img className="inline-block h-10 w-10 rounded-full" src={profilePhoto} alt="" />
                </div>
                <div className="flex-1 pr-2 pt-2 mt-2">
                  <textarea
                    className="pl-2 bg-transparent text-gray-400 font-medium text-lg w-full resize-none focuc-visible:border-opacity-0"
                    rows={5}
                    cols={50}
                    placeholder="What's happening?"
                    value={post}
                    maxLength={250}
                    onChange={(event) => {
                      const { value } = event.target
                      setPost(value)
                    }}
                  ></textarea>
                  <p className="text-gray-400 text-xs text-right pb-4">{`${post.length}/250`}</p>
                </div>
              </div>
              {showPhotoPicker && (
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  flex={2}
                  mb={4}
                  alignItems={'stretch'}
                  justifyContent={'stretch'}
                >
                  <HStack justifyContent="space-between" mb={2} display={true ? 'flex' : 'none'}>
                    <ButtonChakra
                      leftIcon={<IoAdd />}
                      variant="outline"
                      onClick={() => {
                        fileInputRef?.current?.click()
                      }}
                    >
                      Add
                    </ButtonChakra>
                    {attachments?.length > 0 && (
                      <ButtonChakra
                        leftIcon={<IoIosStarOutline />}
                        variant="outline"
                        disabled={isCurrentDefault}
                        onClick={() => {
                          if (isCurrentDefault) return
                          setDefaultIndex(currentImageIndex)
                        }}
                      >
                        {isCurrentDefault ? 'Default' : 'Set Default'}
                      </ButtonChakra>
                    )}
                    <ButtonChakra
                      leftIcon={<IoRemove />}
                      variant="outline"
                      onClick={handleOnImageRemoved}
                    >
                      Remove
                    </ButtonChakra>
                  </HStack>
                  {attachments?.length < 1 ? (
                    <Center
                      flex={1}
                      border={1}
                      borderColor={'gray.600'}
                      borderStyle={'solid'}
                      rounded="md"
                      cursor={'pointer'}
                      onClick={() => {
                        fileInputRef?.current?.click()
                      }}
                    >
                      <VStack p={5}>
                        <Icon boxSize={32} as={IoMdAttach}></Icon>
                        <Text fontSize="lg" fontWeight="bold">
                          Add Files
                        </Text>
                      </VStack>
                    </Center>
                  ) : (
                    <PostCarousel
                      attachments={attachments}
                      useLocalPDFViewer={true}
                      setCurrentIndex={(index) => {
                        setCurrentImageIndex(index)
                      }}
                    />
                  )}
                  <input
                    ref={fileInputRef}
                    type="file"
                    accept="*"
                    multiple
                    id={Math.random().toString()}
                    onChange={handleOnImagePick}
                    onClick={(event) => {
                      event.target.value = ''
                    }}
                    hidden
                  />
                </Box>
              )}
              {showTagPicker && (
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  mb={8}
                  mx={4}
                  w={'full'}
                  alignItems={'center'}
                  justifyContent={'stretch'}
                >
                  <Divider w={10} mr={2} />
                  <Text color={'gray.500'} mr={2}>
                    with
                  </Text>
                  <CustomSelect
                    isMulti={true}
                    options={userContacts}
                    selectedOptions={tags || []}
                    onChange={(selected, a, b) => {
                      setTags(selected)
                    }}
                    placeholder={'Pick Connections'}
                    closeMenuOnSelect={false}
                    optionBgColor={graySoft}
                    brandColor={primary500}
                  />
                </Box>
              )}

              {repostId && (
                <div className="flex">
                  <div className="flex-1 pr-2 pt-2 mt-2 pb-4">
                    <RePost repostId={repostId} />
                  </div>
                </div>
              )}
              {postData.repostId && (
                <div className="flex">
                  <div className="flex-1 pr-2 pt-2 mt-2 pb-4">
                    <RePost repostId={postData.repostId} />
                  </div>
                </div>
              )}
              <div className="flex mr-2">
                <div className="flex-1 px-2 ">
                  <div className="flex">
                    <HStack>
                      <EmojiPickerPopover
                        onSelectEmoji={(emoji) => setPost((prev) => `${prev}${emoji}`)}
                      />
                      <IconButton
                        rounded={'full'}
                        variant={'ghost'}
                        aria-label={''}
                        size={'md'}
                        title={'File Attach'}
                        color={'primary.500'}
                        icon={<IoAttachOutline size={32} />}
                        onClick={() => {
                          if (attachments?.length > 0) {
                            return
                          }

                          setShowPhotoPicker.toggle()
                        }}
                      />
                      <IconButton
                        rounded={'full'}
                        variant={'ghost'}
                        aria-label={''}
                        size={'md'}
                        title={'Tag Connection'}
                        color={'primary.500'}
                        icon={<IoPersonOutline size={24} />}
                        onClick={() => {
                          if (tags?.length > 0) {
                            return
                          }
                          setShowTagPicker.toggle()
                        }}
                      />
                      <PrivacySelector
                        privacyMode={PRIVACY_MODES[privacyMode]}
                        onPrivacyChange={(value) => {
                          setPrivacyMode(value)
                        }}
                      />
                      {privacyMode === 'private' && (
                        <CustomSelect
                          isMulti={true}
                          options={userContacts}
                          selectedOptions={shareToUsers || []}
                          onChange={(selected, a, b) => {
                            setSharedToUsers(selected)
                          }}
                          placeholder={'Pick Connections'}
                          closeMenuOnSelect={false}
                          optionBgColor={graySoft}
                          brandColor={primary500}
                        />
                      )}
                    </HStack>
                  </div>
                </div>

                <div className="flex">
                  <div className="flex flex-1 justify-end mr-2">
                    <Button
                      text={isAdd ? `Post` : 'Update'}
                      disabled={post.length < 1 && attachments?.length < 1}
                      isLoading={isLoading}
                      onClick={onPostHandle}
                      className={'lg:w-24 sm:w-0'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
    //  </ModalCustom>
  )
}

export default CreatePost
